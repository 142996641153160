require('./bootstrap');

import './helpers/filters.js';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import Vuex from 'vuex';
import VueYouTubeEmbed from 'vue-youtube-embed';
import store from './vuex/guestStore';
import VTooltip from 'v-tooltip';
import VueTour from 'vue-tour';
import VueShepherd from 'vue-shepherd';

require('vue-tour/dist/vue-tour.css');
require('shepherd.js/dist/css/shepherd.css');

window.Vapor = require('laravel-vapor');
window.Vue = require('vue');

Vue.use(Vuex);
Vue.use(VueYouTubeEmbed);
Vue.use(VTooltip);
Vue.use(VueTour);
Vue.use(VueShepherd);

const files = require.context('./', true, /\.vue$/i);
files.keys().map(key =>
    Vue.component(
        key
            .split('/')
            .pop()
            .split('.')[0],
        files(key).default
    )
);

Vue.mixin({
    computed: {
        isMobile: function () {
            return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        },
    },
    methods: {
        asset: window.Vapor.asset,
        /**
         * Copy a string to clipboard
         * @param  {String} string         The string to be copied to clipboard
         * @return {Boolean}               returns a boolean correspondent to the success of the copy operation.
         * @see https://stackoverflow.com/a/53951634/938822
         */
        copyToClipboard(string) {
            let textarea;
            let result;

            try {
                textarea = document.createElement('textarea');
                textarea.setAttribute('readonly', true);
                textarea.setAttribute('contenteditable', true);
                textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
                textarea.value = string;

                document.body.appendChild(textarea);

                textarea.focus();
                textarea.select();

                const range = document.createRange();
                range.selectNodeContents(textarea);

                const sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);

                textarea.setSelectionRange(0, textarea.value.length);
                result = document.execCommand('copy');
            } catch (err) {
                console.error(err);
                result = null;
            } finally {
                document.body.removeChild(textarea);
            }

            // manual copy fallback using prompt
            if (!result) {
                const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
                const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
                result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
                if (!result) {
                    return false;
                }
            }
            return true;
        }
    }
});

function orientation() {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        if(screen.availHeight < screen.availWidth){
            document.getElementById('turn').style.display = 'flex';
            document.getElementById('app').style.display = 'none';
        } else {
            document.getElementById('turn').style.display = 'none';
            document.getElementById('app').style.display = 'block';
        }
    }
}
orientation();
window.addEventListener("orientationchange", function() {
    orientation();
}, false);

const app = new Vue({
    el: '#app',
    store
});

if (APP_ENV == 'production') {
    Sentry.init({
        debug: true,
        dsn: SENTRY_LARAVEL_DSN,
        integrations: [
            new Integrations.Vue({ app, attachProps: true, logErrors: true, tracing: false })
        ]
    });

    if (window.authUser) {
        Sentry.configureScope(function(scope) {
            scope.setUser(window.authUser);
        });
    }
}
