<template>
    <div class="header">
        <div class="grid-row grid-row--aic grid-row--jcb">
            <div class="grid-col grid-col--auto">
                <a href="/">
                    <img
                        :src="asset('img/logo.png')"
                        :srcset="asset('img/logo@2x.png')"
                        width="72"
                        height="16"
                        alt="AARP"
                    />
                </a>
            </div>

            <div class="header__back grid-col grid-col--7" v-if="backToFeed">
                <button type="button" class="button button--xs" @click="goBack">
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2.4"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <line x1="19" y1="12" x2="5" y2="12"></line>
                        <polyline points="11 18 5 12 11 6"></polyline>
                    </svg>
                    <span>Back to feed</span>
                </button>
            </div>

            <div class="grid-col grid-col--auto">
                <a href="/profile">
                    <div class="grid-row grid-row--smg grid-row--aic">
                        <div class="grid-col grid-col--auto">
                            <div class="rfeed-user__avatar">{{ guest && guest.email ? guest.email.charAt(0) : '' | capitalize }}</div>
                        </div>
                        <div class="grid-col grid-col--auto">
                            <div class="rfeed-user__email">{{ guest.email }}</div>
                        </div>
                    </div>
                </a>
                <button type="button" class="header__burger">
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2.4"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'main-header',
    props: [],
    mounted() {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            pathway: 'pathway',
            backToFeed: 'backToFeed',
            guest: 'guest'
        }),
        emptyPathway() {
            return _.isEmpty(this.pathway);
        }
    },
    methods: {
        goBack() {
            window.location = '/';
        }
    }
};
</script>

<style lang="scss">
.header {
    z-index: 200;

    .header__back {
        button {
            border: none;
            padding: 5px;
            margin: -5px;
        }
    }
}
</style>
