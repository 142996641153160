<template>
    <v-row style="z-index: 9999;">
        <v-dialog v-model="localDialog" max-width="80%">
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card>
                <v-card-title class="headline">
                    Drag and drop to change content order
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="localDialog = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-col cols='12'>
                        <draggable
                            :list="content"
                            class="list-group"
                            ghost-class="ghost"
                            @end="contentMoved()"
                        >
                            <div
                                class="list-group-item"
                                v-for="(c, index) in content"
                                :key="c.id"
                            >
                                {{ index + 1 }}. {{ c.title }}
                            </div>
                        </draggable>
                    </v-col>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import draggable from 'vuedraggable';
export default {
    props: ['contentTypes', 'dialog'],
    components: { draggable },
    data() {
        return {
            localDialog: this.dialog,
            content: [],
            overlay: false,
            selectedContentType: ''
        };
    },
    created() {
        this.loadContent();
    },
    methods: {
        loadContent() {
            axios.get('/admin/content/get-resources/')
                .then(res => {
                    this.content = res.data.content
                })
        },
        contentMoved() {
            this.content.map(function(c, index) {
                c.order = index + 1;
            });

            axios.put('/admin/sort-content', {content: this.content})
                .then(res => {
                    console.log('done');
                });
        }
    },
    watch: {
        localDialog(value) {
            this.content = [];
            this.$emit('modalClosed');
        },
    }
};
</script>

<style lang="scss">
iframe {
    max-width: 100%;
}
</style>
