<template>
    <div
        class="flow-node"
        :class="{
            ['flow-node--' + typeMap[node.content.content_type.name]]: !node.multi_content,
            'flow-node--multi': node.multi_content,
            'flow-node--lg': size === 'lg',
            'flow-node--xl': size === 'xl',
            'origin-node': node.origin
        }"
        @mouseover="$set(hovering, node.id, true)"
        @mouseout="$set(hovering, node.id, false)"
        draggable="true"
        @dragstart="startNodeDrag($event, node.id)"
        @drop="$emit('onDropEvent', $event, node)"
    >
        <div class="flow-node__cover" v-if="['lg', 'xl'].includes(size)">
            <img :src="node.content.thumbnail_sm" width="332" alt="" v-if="size === 'xl'" />
            <img :src="node.content.thumbnail_sm" width="166" alt="" v-else />
        </div>
        <div class="flow-node__title">
            {{ node.multi_content ? 'MultiContent' : node.content.title }}
        </div>
        <button
            class="flow-node__button flow-node__button--delete"
            @click="$emit('delete')"
        ></button>
        <v-menu
            v-if="!node.multi_content"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
            :nudge-right="8"
            :z-index="300"
        >
            <template v-slot:activator="{ on, attrs }">
                <button
                    class="flow-node__button flow-node__button--info"
                    v-bind="attrs"
                    v-on="on"
                ></button>
            </template>
            <info-popover
                :item="node.content"
                :type-map="infoPopoverTypeMap"
                :key="`popover-${node.content.id}`"
            ></info-popover>
        </v-menu>

        <button
            @click="$emit('showMultiContentInfo')"
            class="flow-node__button flow-node__button--info"
            v-else
        ></button>

        <button
            v-if="node.next.length > 1"
            class="flow-node__button flow-node__button--split"
            @click="$emit('openRules')"
        ></button>
    </div>
</template>
<script>
export default {
    name: 'node',
    props: ['node', 'size'],
    mounted() {},
    data() {
        return {
            typeMap: {
                QuestionSet: 'question',
                Iframe: 'iframe',
                Video: 'video',
                Text: 'article',
                Card: 'card',
                Link: 'link',
            },
            hovering: {}
        };
    },
    methods: {
        startNodeDrag: (evt, nodeId) => {
            evt.dataTransfer.dropEffect = 'move';
            evt.dataTransfer.effectAllowed = 'move';
            evt.dataTransfer.setData('type', 'node');
            evt.dataTransfer.setData('nodeId', nodeId);
        }
    },
    computed: {
        infoPopoverTypeMap() {
            let types = _.clone(this.typeMap);
            types.QuestionSet = 'quest';
            return types;
        }
    },
    watch: {}
};
</script>

<style scoped lang="scss">
.origin-node {
    border: 1px solid #39a71bff;
}
</style>
