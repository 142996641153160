<template>
    <div class='grid-col grid-col--12 mb--100'>
        <h2>Your Caregiving Card Deck</h2>

        <div class='ctt-card-wrap ctt-card-wrap--stack intro-deck' @click='goToDeck'>
            <div class='ctt-card'>
                <div class='ctt-card__cont'>
                    <h3 class='h2--sm'>Care to talk?</h3>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    props: [],
    mixins: [collectorActions],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {
        goToDeck() {
            window.location = '/deck';
        }
    },
    watch: {}
};
</script>

<style lang="scss">
.ctt-card-wrap.intro-deck {
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
    max-width: 280px;
    cursor: pointer;

    .ctt-card {
        background: #EE2E24;
        border: none;
    }

    .ctt-card__cont {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 290px;

        h3 {
            align-self: center;
            color: #fff;
        }
    }
}

.intro-deck::after, .intro-deck::before {
    background-color: #EE2E24;
    border-radius: 10px;
}
</style>
