<template>
    <div class="video-container">
        <div class="deepdive-thanks" >
            <h4>Intro video</h4>
            <div class="mt--200 resource__video">
                <youtube
                    class="resource__cover"
                    ref="youtube"
                    :video-id="videoId"
                    player-width="100%"
                    player-height="100%"
                ></youtube>
            </div>

            <button type="button" @click="exit" class="deepdive-thanks__close">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </button>
        </div>
    </div>
</template>
<script>
import { getIdFromURL } from 'vue-youtube-embed';

export default {
    name: 'intro-video',
    created() {
        this.videoId = getIdFromURL(this.videoId);
    },
    data() {
        return {
            videoId: 'https://youtu.be/N3EAMPhflpA',
        };
    },
    methods: {
        exit() {
            this.$emit('closeVideo', false)
        },
    }
};
</script>
<style lang="scss" scoped>
.video-container {
    z-index: 999;
    width: 75%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.slide-enter-active {
    -moz-transition-duration: 0.1s;
    -webkit-transition-duration: 0.1s;
    -o-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -moz-transition-timing-function: ease;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
}

.slide-enter-to,
.slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
</style>
