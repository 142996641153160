<template>
    <form @submit.prevent="submit" class="settings-account">
        <div class="settings-account__cont">
            <div class="grid-row grid-row--jcc">
                <div class="grid-col grid-col--auto">
                    <div class="settings-account__avatar">
                        <span v-if='guest.name'>{{ guest.name ? guest.name.charAt(0) : '' | capitalize() }}</span>
                        <span v-else>{{ guest.email ? guest.email.charAt(0) : '' | capitalize() }}</span>
                    </div>
                </div>
                <div class="mt--050 grid-col grid-col--12 removed--xs"></div>
                <div class="mt--200 grid-col grid-col--12 grid-col--xs-12">
                    <label class="p">Name</label>
                    <div class="mt--050 input">
                        <input type="text" v-model="guest.name" class="input__input" />
                    </div>
                </div>

                <div class="mt--200 grid-col grid-col--12">
                    <label class="p">Email Address</label>
                    <div class="mt--050 input">
                        <input type="email" v-model="guest.email" class="input__input" />
                    </div>
                </div>
            </div>
        </div>
        <div class="settings-account__foot">
            <div class="grid-row grid-row grid-row--aic grid-row--jcb">
                <div class="grid-col grid-col--auto"></div>
                <div class="grid-col grid-col--auto">
                    <button type="submit" class="button button--primary">
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2.4"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path
                                d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"
                            ></path>
                            <polyline points="17 21 17 13 7 13 7 21"></polyline>
                            <polyline points="7 3 7 8 15 8"></polyline>
                        </svg>
                        <span>Save<span class="removed--xs"> Changes</span></span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    name: 'profile-editor',
    mixins: [collectorActions],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {
        submit() {
            axios.put('/profile/' + this.guest.id, this.guest).then(res => {
                this.$emit('profileUpdated');
                this.$store.commit('SET_GUEST', res.data.guest);
                this.updateProfileAction(this.guest);
            });
        }
    },
    watch: {}
};
</script>

<style lang="scss">
.container {
    margin-right: auto;
    margin-left: auto;
    padding: 0 50px;
    width: 100%;
}

dl {
    display: inline-block;
    width: 100%;

    dt {
        display: inline-block;
        float: left;
        margin-right: 20px;
    }
}

.settings-account__avatar:after {
    border: none;
    background: none;
}
</style>
