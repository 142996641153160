<template>
    <div>
        <loading :active="deckLoading" />
        <div id='overlay' v-if='showSuggestionModal || shouldShowLoginModal || shouldShowRewardsModal'></div>
        <div class="ctt-banner" v-show='snackbarMessage.length'>{{snackbarMessage}}</div>
        <deck-header></deck-header>
        <deck-subheader></deck-subheader>
        <div class="mt--300 grid-col grid-col--12" style='height: 50px'></div>

        <deck-content></deck-content>

        <suggest-new-card-modal
            v-if="showSuggestionModal"
        ></suggest-new-card-modal>

        <login-register-modal v-if="shouldShowLoginModal"></login-register-modal>
        <rewards-modal v-if="shouldShowRewardsModal"></rewards-modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {showAt, hideAt} from 'vue-breakpoints'
import Tour from "../../../mixins/Tour";
import LoginRegisterModal from "../sso/LoginRegisterModal";

export default {
    name: 'deck-view',
    props: ['originalGuest', 'allCards', 'guestCards', 'deckCategories', 'resources'],
    mixins: [collectorActions, Tour],
    components: { Loading, hideAt, showAt, LoginRegisterModal },
    mounted() {
        this.$store.commit('SET_GUEST', this.originalGuest);
        this.$store.commit('SET_BACK_TO_FEED', true);
        this.$store.commit('SET_GUEST_CARDS', this.guestCards);
        this.$store.commit('SET_DECK_CARDS', this.allCards);
        this.$store.commit('SET_DECK_LOADING', false);
        this.$store.commit('SET_DECK_CATEGORIES', this.deckCategories);
        this.$store.commit('SET_DECK_RESOURCES', this.resources);
        this.$store.commit('SET_BACK_TO_FEED', false);

        console.log('CARE-APP: DeckView.vue: Guest rewards enabled', this.guest.user_rewards);

        // collector action
        this.deckOpen();

        setTimeout(() => {
            if (! this.isMobile && ! this.tourIsCompleteFor('deck', this.guest.id)) {
                this.$nextTick(() => {
                    const tour = this.$shepherd({
                        useModalOverlay: true,
                        defaultStepOptions: {
                            scrollTo: { behavior: 'smooth', block: 'center' },
                            popperOptions: {
                                modifiers: [
                                    { name: 'offset', options: { offset: [0, 20] } },
                                ]
                            }
                        },
                    });

                    tour.on('complete', () => {
                        this.completeTourForGuest('deck', this.guest.id);
                    })

                    this.getTourStepsForDeck(tour).forEach(step => {
                        tour.addStep(step);
                    })

                    tour.start();
                });
            }

            if (this.isMobile && ! this.tourIsCompleteFor('deck', this.guest.id)) {
                this.$nextTick(() => {
                    const tour = this.$shepherd({
                        useModalOverlay: true,
                        defaultStepOptions: {
                            scrollTo: { behavior: 'smooth', block: 'center' },
                            popperOptions: {
                                modifiers: [
                                    { name: 'offset', options: { offset: [0, 20] } },
                                ]
                            }
                        },
                    });

                    tour.on('complete', () => {
                        this.completeTourForGuest('deck', this.guest.id);
                    })

                    this.getTourStepsForMobileDeck(tour).forEach(step => {
                        tour.addStep(step);
                    })

                    tour.start();
                });
            }
        }, 100)
    },
    data() {
        return {
            overlay: false,
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            showSuggestionModal: 'showSuggestionModal',
            deckLoading: 'deckLoading',
            snackbarMessage: 'snackbarMessage',
            showLoginModal: 'showLoginModal',
            showRewardsModal: 'showRewardsModal',
            dismissedRewardsModal: 'dismissedRewardsModal',
            dismissedLoginRegisterModal: 'dismissedLoginRegisterModal',
        }),
        shouldShowRewardsModal() {
            return !this.dismissedRewardsModal && this.showRewardsModal;
        },
        shouldShowLoginModal() {
            return !this.dismissedLoginRegisterModal && this.showLoginModal;
        }

    },
    methods: {

    },
    watch: {}
};
</script>

<style lang="scss">
.ctt-content {
    padding: 15px 10px;
    position: relative;
    overflow: hidden;
}

.p-width--100 {
    width: 100%;
}
</style>
