<template>
    <div class="onboard__slide" :class="{ current: isCurrent }">
        <pathway-nodes-header
            :show-resources="false"
            @goBack="$emit('loadIntro')"
        ></pathway-nodes-header>
        <div class="onboard-quiz">
            <div class="onboard-quiz__slide active">
                <form @submit.prevent="login" method="POST" class="onboard-callout">
                    <h1 class="tc--secondary onboard-callout__title ta--center">Login!</h1>

                    <h4 class="onboard-callout__subtitle">
                        Enter your email to access your<br />
                        personalized card deck
                    </h4>

                    <p class="onboard-callout__label">
                        We will email you a link to login into your account
                    </p>

                    <div class="input onboard-callout__input">
                        <input
                            dusk="introEmailInput"
                            type="email"
                            v-model="email"
                            placeholder="Enter your email address..."
                            class="input__input"
                        />
                    </div>

                    <p class="tc--primary" v-show="message !== ''">{{ message }}</p>
                    <p class="tc--tertiary" v-show="errorMessage !== ''">{{ errorMessage }}</p>

                    <button
                        type="submit"
                        id="onboard-intro__button"
                        dusk="submitIntroLogin"
                        class="button button--rounded button--primary onboard-callout__button"
                    >
                        <span>Send link</span>
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    name: 'intro-login-form',
    props: ['isCurrent'],
    mixins: [collectorActions],
    data() {
        return {
            message: '',
            errorMessage: '',
            email: ''
        };
    },
    methods: {
        login() {
            this.message = '';
            this.errorMessage = '';

            axios
                .post('/send-link', { email: this.email })
                .then(res => {
                    this.message = res.data.message;
                    this.introLoginAction(this.email, this.message);
                    this.email = '';
                })
                .catch(err => {
                    this.errorMessage = err.response.data.errors.email[0];

                    this.introLoginAction(this.email, this.errorMessage);
                });
        }
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            pathway: 'pathway'
        })
    },
    watch: {}
};
</script>
