<template>
    <div class="login-container">
        <div class="deepdive-thanks" >
            <h4>Register or Login to save your progress</h4>
            <div class="mt--200">
                <div class="grid-row grid-row--jcc mt--100">
                    <ul style="text-align: left; margin-left: 35px">
                        <li>Access your 10 customized cards</li>
                        <li>Add or remove additional cards</li>
                        <li>Find supporting resources and videos</li>
                        <li>Save all your interactions</li>
                    </ul>
                </div>

                <div class="grid-row grid-row--jcc mt--300">
                    <div class="grid-col grid-col--auto">
                        <button type="button" class="button button--primary" @click="openRegister">
                            Register
                        </button>
                    </div>
                    <div class="grid-col grid-col--auto">
                        <button type="button" class="button button--tertiary" @click="exit">
                            Decline
                        </button>
                    </div>
                </div>
                <div class="grid-row grid-row--jcc mt--025">
                    Already have an AARP account? <a href="#" @click.prevent="openLogin" style="color: #157EB8">&nbsp;Login here</a>
                </div>
            </div>

            <button type="button" @click="exit" class="deepdive-thanks__close">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </button>
        </div>
    </div>
</template>
<script>

import { mapGetters } from 'vuex';

export default {
    name: 'login-register-modal',
    data() {
        return {

        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest'
        })
    },
    methods: {
        exit() {
            this.$store.commit('SET_SHOW_LOGIN_MODAL', false);
        },

    }
};
</script>
<style lang="scss" scoped>
.login-container {
    z-index: 999;
    width: 45%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>
