<template>
    <div>
        <h1>Loading</h1>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../mixins/collectorActions';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {showAt, hideAt} from 'vue-breakpoints'

export default {
    name: 'loading-view',
    props: ['originalGuest'],
    mixins: [collectorActions],
    //components: { Loading, hideAt, showAt, LoginRegisterModal },
    created() {
        console.log('LOADING VIEW CREATED');
        this.$store.commit('SET_GUEST', this.originalGuest);
        console.log('LOADING VIEW MOUNTED', this.guest, this.originalGuest);
    },
    data() {
        return {
            overlay: false,
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
        }),
    },
    methods: {

    },
    watch: {}
};
</script>

<style lang="scss">
.ctt-content {
    padding: 15px 10px;
    position: relative;
    overflow: hidden;
}

.p-width--100 {
    width: 100%;
}
</style>
