<template>
    <div
        :class='`ctt-card border-${cssCategory} ${inListCss}`'
    >

        <div :class="`ctt-card__category ctt-card__category--${cssCategory}`">{{ card.settings.category }}</div>
        <div class='ctt-card__cont'>
            <h3 class='h2--sm'>{{ card.title }}</h3>
            <p class="ctt-card__submitted" v-if='card.suggested_by'>Submitted by {{card.suggested_by}}</p>
        </div>
        <div class='ctt-card__foot'>
            <template v-if='deckTab === "customize"'>
                <button
                    class="ctt-card__add-to-deck"
                    v-if='!hasCard'
                    @click='addCardToDeck()'
                >Add to My Card Deck</button>
                <template v-else>
                    <button
                        v-tooltip="isFavorite ? `Remove from My Likes` : `Add to My Likes`"
                        class="ctt-card__favourite"
                        :class="{'active': isFavorite}"
                        @click='toggleFavorite()'
                    ></button>
                    <div
                        class="ctt-card__added-to-deck"
                    >In My Deck</div>
                    <button
                        v-tooltip="`Remove from My Card Deck`"
                        class="ctt-card__remove-from-deck"
                        @click='removeCardFromDeck()'
                        :disabled='deckLoading'
                    ></button>
                </template>
            </template>
            <template v-else>
                <button
                    v-if='! sharedDeckFrom'
                    v-tooltip="isFavorite ? `Remove from My Likes` : `Add to My Likes`"
                    class="ctt-card__favourite"
                    :class="{'active': isFavorite}"
                    @click='toggleFavorite()'
                ></button>
                <div class='ctt-card__count'>
                    {{ card.favorites_count ? card.favorites_count : '0 ' }} {{ 'caregiver' | pluralize(card.favorites_count) }} liked this card.
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
export default {
    name: 'deck-card',
    props: ['inGrid', 'card'],
    mixins: [collectorActions],
    data() {
        return {

        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            deckTab: 'deckTab',
            guestCards: 'guestCards',
            deckLoading: 'deckLoading',
            sharedDeckFrom: 'sharedDeckFrom',
            addedCardsTotal: 'addedCardsTotal',
        }),
        cssCategory() {
            return this.card ? this.card.settings.category.toLowerCase() : '';
        },
        hasCard() {
          return _.findIndex(this.guestCards, { id: this.card.id}) > - 1;
        },
        isFavorite() {
            return this.guest.favorites.includes(this.card.id)
        },
        inListCss() {
            return this.inGrid ? 'ctt-card--inlist' : '';
        }
    },
    methods: {
        addCardToDeck() {
            this.deckAddCard(this.card);
            this.$store.dispatch('addCardToDeck', this.card).then(() => {
                if (this.addedCardsTotal >= 3) {
                    this.distributeReward(this.guest);

                    if (this.guest.user_fedid && this.guest.user_rewards != 1) {
                        this.$store.commit('SET_SHOW_REWARDS_MODAL', true);
                    } else {
                        this.$store.commit('SET_SHOW_LOGIN_MODAL', true);
                    }
                }
            });
        },
        removeCardFromDeck() {
            this.deckRemoveCard(this.card);
            this.$store.dispatch('removeCardFromDeck', this.card);
        },
        toggleFavorite() {
            this.deckFavoriteCardToggle(this.card);
            this.$store.dispatch('toggleFavoriteCard', this.card);
        }
    },
    watch: {}
};
</script>

<style lang="scss">

</style>
