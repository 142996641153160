<template>
    <div class="ctt-content">
        <template>

            <div class='grid-row grid-row--nog mb--200 removed--md' v-show="showDeckCategories">
                <div class="ctt-categories grid-col">
                    <template v-if='!sharedDeckFrom'>
                        <a @click="filterFavorites"
                           class="ctt-categories__item ctt-categories__item--fav"
                           :class="{'active': onlyFavorites}"
                        >My Likes <span>{{ guest && guest.favorites  ? guest.favorites.length : 0 }}</span></a>
                        <div class="ctt-categories__space"></div>
                    </template>
                    <a
                        class="ctt-categories__item ctt-categories__item--all"
                        :class="{'active': categoryFilter === 'all'}"
                        @click="setCategoryFilter('all')"
                        >All</a>

                    <a
                        v-for='category in deckCategories'
                        :key='category'
                        class="ctt-categories__item"
                        :class="[{'active': category === categoryFilter}, `ctt-categories__item--${category.toLowerCase()}`]"
                        @click='setCategoryFilter(category)'
                    >{{ category }}</a>

                </div>
                <div class="ctt-sorting grid-col grid-col--auto">
                    <label class="ctt-sorting__label">Sort by:</label>
                    <button class="ctt-sorting__toggle">{{ cardOrderMap[cardOrder] }}</button>
                    <div class="ctt-sorting__dropdown">
                        <a @click="setCardOrder('most_favorited')" class="ctt-sorting__option"><svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor"><path d="M15 7.11107L13.25 8.88885L8.00002 3.55551L2.74999 8.88888L1 7.11111L7.99998 0L15 7.11107Z"></path><path d="M15 14.2222L13.25 16L8.00002 10.6666L2.74999 16L1 14.2222L7.99998 7.11111L15 14.2222Z"></path></svg>Most Liked</a>
                        <a @click="setCardOrder('newest')" class="ctt-sorting__option"><svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor"><path d="M5.10526 16L12.2632 8.8421L8.47368 6.31579L10.1579 0L3 7.15789L6.78947 9.68421L5.10526 16Z"></path></svg>Newest Cards</a>
                        <a @click="setCardOrder('oldest')" class="ctt-sorting__option"><svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor"><path d="M14 3H13V1C13 0.734784 12.8946 0.48043 12.7071 0.292893C12.5196 0.105357 12.2652 0 12 0C11.7348 0 11.4804 0.105357 11.2929 0.292893C11.1054 0.48043 11 0.734784 11 1V3H5V1C5 0.734784 4.89464 0.48043 4.70711 0.292893C4.51957 0.105357 4.26522 0 4 0C3.73478 0 3.48043 0.105357 3.29289 0.292893C3.10536 0.48043 3 0.734784 3 1V3H2C1.46957 3 0.960859 3.21071 0.585786 3.58579C0.210714 3.96086 0 4.46957 0 5L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3ZM2 14V7H14V14H2Z"></path></svg>Oldest Cards</a>
                    </div>
                </div>
            </div>

            <transition name="fade" mode="out-in" v-if="deckTab === 'caregiving_resources'" key="transition-cards">
                <div>
                    <div class="grid-row grid-row--smg grid-row--aic mt--250 cards-image-header-container" @click="goToMyDeckTab">
                        <div class="grid-col">

                        </div>
                        <div class="grid-col grid-col--xs-12 grid-col--md-5">
                            <img id="care-to-talk-cards-image" :src="asset('img/care-to-talk-cards.png')" width="400" alt="Care to Talk Cards" />
                        </div>
                        <div class="grid-col grid-col--xs-12 grid-col--md-5">
                            <h3 class="caretotalk-cards-title" style="">Care to Talk Cards</h3>

                            <div class="mt--050">
                                <h5 class="caretotalk-cards-subtitle">Your Personalized Deck of</h5>
                                <h5 class="caretotalk-cards-subtitle">Care to Talk Cards</h5>
                            </div>
                        </div>
                        <div class="grid-col">

                        </div>
                    </div>

                    <caregiving-resource v-for="resource in deckResources" :resource='resource' :key='resource.id + "-" + deckTab'></caregiving-resource>
                </div>
            </transition>

            <transition name="fade" mode="out-in" v-else key="transition-deck" v-else>
                <div>
                    <template v-if='cards.length'>
                        <deck-stack v-if="deckView === 'stack' && cards.length" :cards='cards'></deck-stack>
                        <deck-grid v-else :cards='cards'></deck-grid>
                    </template>
                    <template v-else>
                        <div class='ctt-no--cards'>
                            <h1>Your deck contains no cards in this category</h1>
                        </div>
                    </template>
                </div>
            </transition>

            <div class='mt--200 removed blocked--sm' id='mobile-cats' v-show="showDeckCategories">
                <button class="button button--sm button--blocked button--primary" onclick="document.getElementById('ctt-filter').classList.toggle('active');"><svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor"><path d="M16 2C17.1046 2 18 2.89543 18 4V13C18 13.5523 18.4477 14 19 14V14C19.5523 14 20 13.5523 20 13V2C20 0.895431 19.1046 0 18 0H7C6.44772 0 6 0.447715 6 1V1C6 1.55228 6.44772 2 7 2H16Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M2 6C0.895431 6 0 6.89543 0 8V18C0 19.1046 0.895431 20 2 20H12C13.1046 20 14 19.1046 14 18V8C14 6.89543 13.1046 6 12 6H2ZM12 10C12 8.89543 11.1046 8 10 8H4C2.89543 8 2 8.89543 2 10V16C2 17.1046 2.89543 18 4 18H10C11.1046 18 12 17.1046 12 16V10Z"></path><path d="M15 7C15 5.89543 14.1046 5 13 5H4C3.44772 5 3 4.55228 3 4V4C3 3.44772 3.44772 3 4 3H15C16.1046 3 17 3.89543 17 5V16C17 16.5523 16.5523 17 16 17V17C15.4477 17 15 16.5523 15 16V7Z"></path></svg><span>Categories</span></button>
                <div id="ctt-filter" class="ctt-filter">
                    <div class="ctt-filter__cont">
                        <div class="ctt-filter__label">Sort by</div>
                        <div class="ctt-sorting ctt-tour-sorting">
                            <button class="ctt-sorting__toggle">{{ cardOrderMap[cardOrder] }}</button>
                            <div class="ctt-sorting__dropdown">
                                <a @click="setCardOrder('most_favorited')" class="ctt-sorting__option"><svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor"><path d="M15 7.11107L13.25 8.88885L8.00002 3.55551L2.74999 8.88888L1 7.11111L7.99998 0L15 7.11107Z"></path><path d="M15 14.2222L13.25 16L8.00002 10.6666L2.74999 16L1 14.2222L7.99998 7.11111L15 14.2222Z"></path></svg>Most Liked</a>
                                <a @click="setCardOrder('newest')" class="ctt-sorting__option"><svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor"><path d="M5.10526 16L12.2632 8.8421L8.47368 6.31579L10.1579 0L3 7.15789L6.78947 9.68421L5.10526 16Z"></path></svg>Newest Cards</a>
                                <a @click="setCardOrder('oldest')" class="ctt-sorting__option"><svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor"><path d="M14 3H13V1C13 0.734784 12.8946 0.48043 12.7071 0.292893C12.5196 0.105357 12.2652 0 12 0C11.7348 0 11.4804 0.105357 11.2929 0.292893C11.1054 0.48043 11 0.734784 11 1V3H5V1C5 0.734784 4.89464 0.48043 4.70711 0.292893C4.51957 0.105357 4.26522 0 4 0C3.73478 0 3.48043 0.105357 3.29289 0.292893C3.10536 0.48043 3 0.734784 3 1V3H2C1.46957 3 0.960859 3.21071 0.585786 3.58579C0.210714 3.96086 0 4.46957 0 5L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3ZM2 14V7H14V14H2Z"></path></svg>Oldest Cards</a>
                            </div>
                        </div>
                        <div class="ctt-filter__label">Categories</div>
                        <div class="ctt-categories ctt-tour-categories">
                            <a @click="setCategoryFilter('all')"
                               class="ctt-categories__item ctt-categories__item--all"
                               :class="{'active': categoryFilter === 'all'}"
                            >All</a>
                            <a v-if='!sharedDeckFrom'
                                @click="filterFavorites"
                                class="ctt-categories__item ctt-categories__item--fav"
                                :class="{'active': this.onlyFavorites}"
                            >My Favorites <span>{{ guest && guest.favorites  ? guest.favorites.length : 0 }}</span></a>
                            <div class="ctt-categories__space"></div>
                            <a
                                v-for='category in deckCategories'
                                :key='category'
                                class="ctt-categories__item"
                                :class="[{'active': category === categoryFilter}, `ctt-categories__item--${category.toLowerCase()}`]"
                                @click='setCategoryFilter(category)'
                            >{{ category }}</a>
                        </div>
                        <button class="mt--150 button button--sm button--blocked button--primary" onclick="document.getElementById('ctt-filter').classList.remove('active');">Apply</button>
                        <button class="ctt-filter__close" onclick="document.getElementById('ctt-filter').classList.remove('active');"></button>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
export default {
    name: 'deck-content',
    mixins: [collectorActions],
    mounted() {

    },
    data() {
        return {
            overlay: false,
            categoryFilter: 'all',
            onlyFavorites: false,
            cardOrder: 'newest',
            cardOrderMap: {
                'newest': 'Newest Cards',
                'oldest': 'Oldest Cards',
                'most_favorited': 'Most Liked',
                'least_favorited': 'Least Favorited',
            }
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            deckView: 'deckView',
            deckTab: 'deckTab',
            showSuggestionModal: 'showSuggestionModal',
            guestCards: 'guestCards',
            deckCards: 'deckCards',
            deckResources: 'deckResources',
            deckCategories: 'deckCategories',
            sharedDeckFrom: 'sharedDeckFrom'
        }),
        cards() {
            return this.deckTab === 'my_deck' ? this.guestCards : this.deckCards
        },
        showDeckCategories() {
            return this.deckTab !== 'caregiving_resources'
        }
    },
    methods: {
        setCardOrder(order) {
            this.cardOrder = order;
            this.setCategoryFilter(this.categoryFilter)
        },
        setCategoryFilter(category, onlyFavs = false) {
            this.categoryFilter = category;
            if (this.onlyFavorites && ! onlyFavs) {
                this.onlyFavorites = false;
            }
            this.$store.dispatch('setDeckCategory', {
                category: category,
                favorites: this.onlyFavorites,
                order: this.cardOrder
            });

            this.deckFilterBy(category, this.cardOrder, this.onlyFavorites);
        },
        filterFavorites() {
            this.onlyFavorites = ! this.onlyFavorites;
            this.categoryFilter = 'all';
            this.setCategoryFilter(this.categoryFilter, true);
        },
        goToMyDeckTab() {
            this.$store.commit('SET_DECK_TAB', 'my_deck');
        }
    },
    watch: {
        'deckTab': {
            handler(val) {
                if (val === 'my_deck') {
                    this.cardOrder = 'newest';
                    this.setCategoryFilter('all', false);
                }
            }
        }
    }
};
</script>

<style lang="scss">
.p-width--100 {
    width: 100%;
}

#mobile-cats {
    position: fixed;
    bottom: 10px;
    left: 20px;
    right: 20px;
    z-index: 9;
}

.ctt-no--cards {
    height: 100%;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 299px);
    align-items: center;

    h1 {
        color: #666;
        text-align: center;
    }
}

.center {
    margin: auto;
    width: 50%;
    border: 3px solid green;
    padding: 10px;
}

.vertical-container {
    position: relative;
    height: 359.594px;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    right: 6%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.caretotalk-cards-title {
    color: #E51C30;
    font-size: 35px;
    text-align: center;
}

.caretotalk-cards-subtitle {
    font-size: 25px;
    text-align: center;
}

#care-to-talk-cards-image {
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.cards-image-header-container {
    cursor: pointer;
}
</style>
