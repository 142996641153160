<template>
    <div class="ctt-modal">
        <div class="ctt-banner danger" v-show='errorMessage.length'>{{errorMessage}}</div>

        <div class="grid-row grid-row--aic grid-row--jcb">
            <div class="grid-col">
                <h4>Suggest New Items</h4>
            </div>
            <div class="grid-col grid-col--auto">
                <button @click='closeModal' class="ctt-modal__close"></button>
            </div>
        </div>
        <div class="mt--125 ctt-modal__cont">
            <div class="ctt-modal__tabs">
                <a
                    @click.stop="switchTab('question')"
                    :class="{'active': modalTab === 'question'}"
                >New Question</a>
                <a
                    @click.stop="switchTab('topic')"
                    :class="{'active': modalTab === 'topic'}"
                >New Topic</a>
            </div>
            <div id="ctt-modal__pane-1"
                 class="ctt-modal__pane"
                 :class="{'active': modalTab === 'question'}"
            >
                <div class="p p--sm ctt-modal__leading">Do you have a good conversation starter? Type and submit it for review by our admins.</div>
                <div class="mt--125 input">
                    <textarea v-model='content'
                              rows="8"
                              cols="7"
                              maxlength="500"
                              placeholder="Type your question here..."
                              class="input__input"
                    ></textarea>
                </div>
            </div>
            <div id="ctt-modal__pane-2"
                 class="ctt-modal__pane"
                 :class="{'active': modalTab === 'topic'}"
            >
                <div class="p p--sm ctt-modal__leading">Sometimes it’s hard to imagine good questions within an area you think that might helpful for our community. In that case you can let us do the hard work. Suggest a new topic/area and we will review it and if relevant, we will add question cards to the topic you suggested.</div>
                <div class="mt--125 input">
                    <textarea
                        rows="4"
                        cols="3"
                        v-model='content' placeholder="Type new topic..."
                        class="input__input"
                    ></textarea>
                </div>
                <div class="mt--100 p p--sm">Ex: Personal Hygiene, Retirement Living</div>
            </div>
        </div>
        <div class="mt--125 grid-row grid-row--aic grid-row--jcb">
            <div class="grid-col grid-col--auto">
                <button @click='closeModal' type="button" class="button button--sm button--ghost">Discard</button>
            </div>
            <div class="grid-col grid-col--auto">
                <button type="button" class="button button--sm button--primary" @click='submitSuggestion()'>Submit for Review</button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
export default {
    name: 'suggest-new-card-modal',
    mixins: [collectorActions],
    data() {
        return {
            modalTab: 'question',
            content: '',
            errorMessage: ''
        };
    },
    mounted() {
        setTimeout(() => {
            window.scrollTo(0, 1);
        }, 200);
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            showSuggestionModal: 'showSuggestionModal'
        })
    },
    methods: {
        closeModal() {
            this.resetContent();
            this.$store.commit('SET_SHOW_SUGGESTION_MODAL', false);
        },
        resetContent() {
            this.content = '';
            this.modelTab = 'question';
        },
        switchTab(tabName) {
            this.content = '';
            this.modalTab = tabName;
        },
        submitSuggestion() {
            this.$store.dispatch('submitSuggestion', {
                type: this.modalTab,
                content: this.content
            }).then(res => {
                this.deckSubmitSuggestion({
                    type: this.modalTab,
                    content: this.content
                });

                this.resetContent();
                this.$store.commit('SET_SHOW_SUGGESTION_MODAL', false);
                this.$store.commit('SET_SNACKBAR_MESSAGE', 'Your ' + this.modalTab +' has been successfully submitted for review!');
                this.$store.commit('SET_DECK_LOADING', false);
            }).catch(err => {
                this.$store.commit('SET_DECK_LOADING', false);
                this.errorMessage = err.response.data.errors.content[0];
                setTimeout(() => this.errorMessage = '', 2000);
            });
        }
    },
    watch: {}
};
</script>

<style lang="scss">
.ctt-modal {
    z-index: 9999;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>
