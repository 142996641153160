<template>
    <div class="grid-row mt--200 resource-row grid-row--jcc" @click='goToResource'>
        <div
            :class="`border-${cssCategory}`"
            class='grid-col--6 grid-col--md-10 resource-container grid-col--xs-12'
        >
            <div class='grid-row grid-row--nog'>
                <div class="grid-col--4 resource-thumbnail-container">
                    <img :src="resource.thumbnail_path" alt="thumb" class="resource-thumbnail">
                </div>

                <div class="grid-col--8 mt--100 resource-details">
                    <div
                        :class="`category-title ctt-card__category ctt-card__category--${cssCategory} title-${cssCategory}`"
                        style="margin-bottom: 5px !important;"
                        v-text="typeMap[resource.content_type.name]"
                    ></div>
                    <h5>{{ resource.title }}</h5>
                    <p style="color: #605A72;">{{ resource.resource_description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
export default {
    name: 'caregiving-resource',
    props: ['inGrid', 'resource', 'type'],
    mixins: [collectorActions],
    data() {
        return {
            typeMap: {
                'Link': 'Caregiving Resource',
                'Video': 'Care to Talk Video',
            }
        }
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            deckTab: 'deckTab',
            guestCards: 'guestCards',
            deckLoading: 'deckLoading',
            sharedDeckFrom: 'sharedDeckFrom'
        }),
        cssCategory() {
            return this.resource.content_type.name.toLowerCase();
        },
    },
    methods: {
        goToResource() {
            if (this.resource.content_type.name === 'Link') {
                window.open(this.resource.url, '_blank');
            } else {
                window.location = '/content/' + this.resource.slug;
            }
        }
    }
};
</script>

<style lang="scss">
.resource-row {
    max-height: 250px;
    cursor: pointer;
    margin-left: -3px;
    margin-right: -3px;
}

.resource-row:last-child {
    margin-bottom: 50px;
}

.resource-container {
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.border-link {
    border: 1.5px solid #891F5E;

    &:hover {
        box-shadow: 0 0 8px rgba(137, 31, 94, 0.7);
    }
}

.border-video {
    border: 1.5px solid #F16B65;

    &:hover {
        box-shadow: 0 0 8px rgba(241, 107, 101, 0.7);
    }
}
.resource-thumbnail-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .resource-thumbnail {
        margin-top: 4px;
        margin-bottom: 4px;
        border-radius: 5px;
        max-height: 140px;
        width: 90%;
    }
}

.resource-details {
    margin-left: 0;
}

.category-title {

    text-transform: none;
    font-size: 16px;
}

.title-link {
    color: #891F5E;
}

.title-video {
    color: #F16B65;
}

@media screen and (max-width: 600px) {
    .title-link, .title-video {
        font-size: 14px;
    }
}
</style>
