<template>
    <div class="onboard">
        <pathway-intro-slide
            @showLogin="showLoginAction"
            @showPathway="showPathwayAction"
            :is-current="introSlide"
        ></pathway-intro-slide>
        <pathway-intro-nodes
            :original-node="originalNode"
            :is-current="showPathway"
            @loadIntro="introSlideAction"
        ></pathway-intro-nodes>

        <intro-login-form :is-current="showLogin" @loadIntro="introSlideAction"></intro-login-form>

        <login-register-modal v-if="showLoginModal"></login-register-modal>
        <div class="background-overlay" v-if="showLoginModal"></div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    name: 'intro-pathway-view',
    props: ['originalGuest', 'originalPathway', 'originalNode'],
    mixins: [collectorActions],
    created() {
        this.$store.commit('SET_GUEST', this.originalGuest);
        this.$store.commit('SET_PATHWAY', this.originalPathway);

        this.openIntroPathwayAction(this.originalPathway.id);
    },
    data() {
        return {
            introSlide: true,
            showPathway: false,
            showLogin: false
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            showLoginModal: 'showLoginModal',
        })
    },
    methods: {
        showLoginAction() {
            this.showLogin = true;
            this.introSlide = false;
            this.showPathway = false;
        },
        showPathwayAction() {
            this.showPathway = true;
            this.introSlide = false;
            this.showLogin = false;
        },
        introSlideAction() {
            this.introSlide = true;
            this.showLogin = false;
            this.showPathway = false;
        }
    },
    watch: {}
};
</script>

<style lang="scss"></style>
