<template>
    <div :class="'notepad' + (active ? ' active' : '')">
        <button id="notepad__toggle" @click="activate()" class="notepad__toggle">
            <i></i>
        </button>
        <div id="notepad__window" class="notepad__window">
            <div class="notepad__head">
                Note <span v-if="lastSaved">Auto saved {{ savedTimePassed }}</span>
            </div>
<!--            <tiptap-editor-->
<!--                v-bind="options"-->
<!--                v-model="note.text"-->
<!--                @update="saveNote"-->
<!--                placeholder="Note text"-->
<!--            ></tiptap-editor>-->
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
// import { TiptapEditor } from 'tiptap-editor/src';
import moment from 'moment';

export default {
    name: 'pathway-notes',
    props: ['disabled'],
    mixins: [collectorActions],
    components: {

    },
    mounted() {
        setInterval(() => {
            if (this.lastSaved) {
                this.savedTimePassed = moment(this.lastSaved).fromNow();
            }

            if (this.note.id) {
                this.lastSaved = moment(this.note.updated_at);
            }
        }, 170);
    },
    data() {
        return {
            lastSaved: null,
            savedTimePassed: null,
            options: {
                content: '',
                editable: true,
                fileSelectorUrl: 'moderator/webinarImages/images',
                fileUploadUrl: 'moderator/webinarImages/store',
                toolbar: [
                    'undo',
                    'redo',
                    'bold',
                    'underline',
                    'italic',
                    'link',
                    'bullet_list',
                    'ordered_list',
                    'format_clear'
                ]
            },
            active: false,
            note: { text: '' }
        };
    },
    computed: {
        ...mapGetters({
            pathway: 'pathway',
            guest: 'guest',
            node: 'node'
        })
    },
    methods: {
        activate() {
          if (! this.disabled) {
              this.active = ! this.active;
          }
        },
        loadNotes() {
            axios
                .get('/guest/api/notes')
                .then(response => {
                    if (response.data.note) {
                        this.note = response.data.note;
                    }
                })
                .catch(error => {
                    console.error('There was an issue grabbing notes');
                });
        },
        saveNote: _.debounce(function() {
            axios
                .post('/guest/api/notes', { text: this.note.text })
                .then(response => {
                    this.note = response.data.note;
                    this.lastSaved = moment();
                })
                .catch(error => {
                    console.error(error);
                    alert('There was an issue saving the note');
                });
        }, 300)
    },
    watch: {
        pathway() {
            this.loadNotes();
        }
    }
};
</script>
