<template>
    <div class="onboard__slide" :class="{ current: isCurrent }">
        <div class="onboard-intro">
            <div class="grid-cont onboard-intro__cont">
                <div class="grid-row grid-row--aic grid-row--jcb">
                    <div class="grid-col grid-col--auto grid-col--md-12 intro-text">
                        <div v-if="isMobile" ref="mobileIntroAnim" @click="showVideoModal=true" class="onboard-intro--mobile-cards">

                        </div>
                        <div class="onboard-intro--icons">
                            <div class="ctt-intro--icon ctt-card__category--favorites"></div>
                            <div class="ctt-intro--icon ctt-card__category--reflections"></div>
                            <div class="ctt-intro--icon ctt-card__category--memories"></div>
                            <div class="ctt-intro--icon ctt-card__category--roadmap"></div>
                            <div class="ctt-intro--icon ctt-card__category--legacy"></div>
                            <div class="ctt-intro--icon ctt-card__category--relationships"></div>
                            <div class="ctt-intro--icon ctt-card__category--wishes"></div>
                        </div>

                        <p
                            class="p p--xxl onboard-intro__subtitle"
                            v-html="pathway.description_html"
                        ></p>

                        <button
                            type="button"
                            id="onboard-intro__button"
                            dusk="introButton"
                            class="button button--rounded button--primary onboard-intro__button"
                            @click="showPathway"
                        >
                            <span>Create my card deck</span
                            ><svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2.4"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <line x1="12" y1="4" x2="12" y2="20"></line>
                                <polyline points="18 14 12 20 6 14"></polyline>
                            </svg>
                        </button>
                    </div>

                    <div class="grid-col grid-col--auto removed--md">
                        <div ref="introAnim" class="onboard-intro__cards2" @click="showVideoModal=true"></div>
                    </div>
                </div>
            </div>
        </div>

        <intro-video v-if="showVideoModal" :key="Date.now().toString()" @closeVideo="showVideoModal=false"></intro-video>
        <div class="background-overlay" v-if="showVideoModal"></div>
    </div>
</template>
<script>
import collectorActions from '../../../mixins/collectorActions';
import { mapGetters } from 'vuex';
import lottie from 'lottie-web'

export default {
    name: 'pathway-intro-slide',
    props: ['isCurrent'],
    mixins: [collectorActions],
    mounted() {
        this.introAnim = lottie.loadAnimation({
            container: this.$refs.introAnim, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: this.asset('json/Care2Talk_Lottie_Full.json'), // the path to the animation json
        })

        this.mobileIntroAnim = lottie.loadAnimation({
            container: this.$refs.mobileIntroAnim, // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: this.asset('json/Care2Talk_Lottie_Flip.json'), // the path to the animation json
        })
    },
    data() {
        return {
            introAnim: {},
            mobileIntroAnim: {},
            showVideoModal: false
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            pathway: 'pathway'
        })
    },
    methods: {
        showLoginForm() {
            this.openLoginFormAction(this.pathway.id);
            this.$emit('showLogin');
        },
        showPathway() {
            this.$emit('showPathway');
        },
    },
    watch: {}
};
</script>

<style lang="scss">
.onboard-intro--mobile-cards {
    width: 70%;
}

.background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 990;
    background: rgba(0, 0, 0, 0.4);
}

.onboard-intro__cards2 {
    cursor: pointer;
}
.text-link {
    &.pointer {
        cursor: pointer;
    }
}

.onboard-intro--icons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-evenly;

    .ctt-intro--icon {
        position: relative;
        font-size: 12px;
        line-height: 26px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.05em;

        &:after {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: 24px;
            height: 24px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
        }
        &:before {
            content: '';
            display: inline-block;
            vertical-align: top;
            width: 36px;
            height: 36px;
            margin-right: 8px;
            border-radius: 50%;
            background-color: currentcolor;
            opacity: 0.2;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 1);
        }
    }

}

.intro-text {
    max-width: 50%;
}

@media screen and (max-width: 1279.98px) {
    .intro-text {
        max-width: 50%;
    }
}
@media screen and (max-width: 1023.98px) {
    .intro-text {
        max-width: 100%;
    }
}

@media screen and (max-width: 767.98px) {
    .intro-text {
        max-width: 100%;
    }
}

@media screen and (max-width: 599.98px) {
    .intro-text {
        max-width: 100%;
    }
}
</style>
