<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar color="success" top v-model="snackbar" :timeout="1500">
            Form saved!
            <v-icon dark>check_circle</v-icon>
        </v-snackbar>
        <v-snackbar color="error" top v-model="snackbarError" :timeout="1500">
            There are errors in form, please fix them.
            <v-icon dark>highlight_off</v-icon>
        </v-snackbar>

        <div class="admin-header">
            <v-row>
                <div class="col-auto">
                    <back-button url="/admin/suggestions"></back-button>
                </div>
                <div class="display-1 col-auto">{{ existingSuggestion ? 'Edit' : 'Create' }} suggestion</div>

                <v-spacer></v-spacer>

                <div class="grid-col grid-col--auto d-flex align-center">
                    <save-button v-if='canSave' @click="saveSuggestion"></save-button>
                </div>
            </v-row>
        </div>

        <v-form ref="form" v-model="valid" :disabled='!canSave' @submit.prevent="saveSuggestion">
            <v-row>
                <v-col cols="12" md="12" sm="12" xs="12">
                    <v-text-field
                        label="Text"
                        :rules="[v => !!v || 'Please enter text']"
                        placeholder="Enter text"
                        v-model="form.content"
                        :error-messages="form.errors.first('content')"
                        outlined
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="12" sm="12" xs="12">
                    <v-radio-group v-model="form.suggestable_type">
                        <v-radio
                            v-for='(name, type) in types'
                            :key='name'
                            hide-details
                            dense
                            :value="type"
                            :label="name"
                        ></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col cols="12" v-if="form.isCard()">
                    <v-select
                        v-model="form.card_category"
                        :items="cardTypes"
                        label="Card Category"
                        hint='Card category will be saved only if content is approved'
                    ></v-select>
                    <small class='error--text' v-if='cardError'>Card category is required if <strong>card</strong> is approved</small>
                </v-col>

                <v-col cols="12" md="12" sm="12" xs="12">
                    <div class="checkboxes-group mb-0">
                        <div class="checkbox" v-if='canSave'>
                            <v-checkbox
                                name="approved_by"
                                hide-details
                                v-model="form.approved_by"
                                label="Approve"
                            ></v-checkbox>
                            <small>By selecting approve checkbox content/topic will be automatically created</small>
                        </div>
                        <div class='checkbox' v-else>
                            <p class='mb-0' v-if='approverName'>Suggestion approved by {{approverName}}</p>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="auto">
                    <save-button v-if='canSave' @click="saveSuggestion"></save-button>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>
<script>
import SuggestionForm from '../../../classes/Form/SuggestionForm';

export default {
    name: 'suggestion-c-u',
    props: ['existingSuggestion', 'types', 'user', 'approver', 'settings', 'cardTypes'],
    created() {
        if (this.existingSuggestion) {
            this.form = new SuggestionForm(this.existingSuggestion);
            this.suggestion = this.existingSuggestion;
            this.approverName = this.approver ? this.approver.name : '';
        }
    },
    data() {
        return {
            suggestion: {},
            form: new SuggestionForm(),
            valid: false,
            snackbar: false,
            snackbarError: false,
            overlay: false,
            cardError: false,
            approverName: ''
        };
    },
    computed: {
      canSave() {
          return this.suggestion && ! this.suggestion.approved_by;
      }
    },
    methods: {
        saveSuggestion() {
            let confirmMessage = 'Are you sure you want to approve creation of new content from this suggestion';
            let formValid = true;
            if (this.form.isCard() && this.form.approved_by) {
                formValid = this.form.card_category !== undefined;
            }

            if (this.$refs.form.validate() && formValid) {
                if (this.form.approved_by) {
                    if (confirm(confirmMessage)) {
                        this.submit();
                    }
                } else {
                    this.submit();
                }
            } else {
                this.snackbarError = true;
                this.cardError = true;
            }
        },
        submit() {
            this.overlay = true;
            if (this.existingSuggestion) {
                this.form
                    .put('/admin/suggestions/' + this.form.id)
                    .then(response => {
                        this.snackbar = true;
                        this.overlay = false;

                        this.form = new SuggestionForm(response.suggestion);
                        this.suggestion = response.suggestion;
                        this.approverName = response.approverName;
                    })
                    .catch(e => {
                        this.overlay = false;
                        this.snackbarError = true;
                    });
            } else {
                this.form
                    .post('/admin/suggestions')
                    .then(response => {
                        window.location = '/admin/suggestions';
                    })
                    .catch(e => {
                        this.overlay = false;
                        this.snackbarError = true;
                    });
            }
        }
    },
    watch: {}
};
</script>
