<template>
    <div class="footer">

    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';

export default {
    name: 'pathway-footer',
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            pathway: 'pathway',
            guest: 'guest',
        })
    },
    methods: {},
    watch: {}
};
</script>

<style lang="scss">
.footer {
    margin-top: 40px;
}
</style>
