export default {
    methods: {
        getTourStepsForDeck(tour) {
            let steps = [
                {
                    attachTo: { element: "#caregiving-resources-button", on: 'right-start' },
                    title: '<div class="p p--xs tutorial__num">TIP 1 of 7</div><h4>Caregiving resources</h4>',
                    text: '<p class="mt--050 step-description">This is the caregiving resources page. Here you\'ll find a list of helpful articles and videos for caregivers like you.</p>',
                    scrollTo: false,
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Next',
                            action() {
                                document.getElementById('my-deck-button').click();
                                setTimeout(() => {
                                    tour.next();
                                }, 300);

                            },
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.deck-nav',
                                },
                            },
                        ]
                    }
                },
                {
                    attachTo: { element: ".mydeck-customize", on: 'right-start' },
                    scrollTo: false,
                    classes: 'feed-step',
                    title: '<div class="p p--xs tutorial__num">TIP 2 of 7</div><h4>Customized Card Deck</h4>',
                    text: '<p class="mt--050 step-description">'+'Select \'My Deck\' to view your personal deck of Care to Talk cards. Select \'Customize\' to browse all Care to Talk cards, and to add or remove cards to your deck.'+'</p>',
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Previous',
                            action: tour.back,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                        {
                            text: 'Next',
                            action: tour.next,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.mydeck-customize',
                                },
                            },
                        ]
                    }
                },
                {
                    attachTo: { element: ".ctt-switcher", on: 'right-start' },
                    title: '<div class="p p--xs tutorial__num">TIP 3 of 7</div><h4>View switcher</h4>',
                    text: '<p class="mt--050 step-description">Select how you view Care to Talk cards here. Grid view displays cards in a grid, and is a great way to organize and review cards. Stack view shows cards one at a time, and is great for focusing on one card and facilitating conversations.</p>',
                    scrollTo: false,
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Previous',
                            action: tour.back,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                        {
                            text: 'Next',
                            action: tour.next,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.ctt-switcher',
                                },
                            },
                        ]
                    }
                },
                {
                    attachTo: { element: ".ctt-categories", on: 'bottom' },
                    title: '<div class="p p--xs tutorial__num">TIP 4 of 7</div><h4>Categories</h4>',
                    text: '<p class="mt--050 step-description">Select a Category to browse by topic. Any card you like will be added to the My Likes category, at the far left.</p>',
                    scrollTo: false,
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Previous',
                            action: tour.back,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                        {
                            text: 'Next',
                            action: tour.next,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.ctt-categories',
                                },
                            },
                        ]
                    }
                },
                {
                    attachTo: { element: ".ctt-sorting", on: 'left' },
                    title: '<div class="p p--xs tutorial__num">TIP 5 of 7</div><h4>Sorting</h4>',
                    text: '<p class="mt--050 step-description">Select from the list here to change how the cards are sorted.</p>',
                    scrollTo: false,
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Previous',
                            action: tour.back,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                        {
                            text: 'Next',
                            action: tour.next,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.ctt-categories',
                                },
                            },
                        ]
                    }
                },
                {
                    attachTo: { element: ".deck-nav", on: 'left' },
                    title: '<div class="p p--xs tutorial__num">TIP 6 of 7</div><h4>Sharing</h4>',
                    text: '<p class="mt--050 step-description">Print all cards in your deck for later use or share a link to your deck with friends or family.</p>',
                    scrollTo: false,
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Previous',
                            action: tour.back,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                        {
                            text: 'Next',
                            action() {
                                document.getElementById('caregiving-resources-button').click();
                                setTimeout(() => {
                                    tour.next();
                                }, 300);

                            },
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.deck-nav',
                                },
                            },
                        ]
                    }
                },
                {
                    attachTo: { element: ".cards-image-header-container", on: 'bottom' },
                    title: '<div class="p p--xs tutorial__num">TIP 7 of 7</div><h4>Your deck</h4>',
                    text: '<p class="mt--050 step-description">When you\'re ready to get started with your deck, click here. Let the conversations begin!</p>',
                    scrollTo: false,
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Previous',
                            action() {
                                document.getElementById('my-deck-button').click();
                                setTimeout(() => {
                                    tour.back();
                                }, 300);

                            },
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.cards-image-header-container',
                                },
                            },
                        ]
                    }
                },
            ];


            return steps;
        },
        getTourStepsForMobileDeck(tour) {
            return [
                {
                    attachTo: { element: ".ctt-tabs", on: 'right-start' },
                    scrollTo: false,
                    classes: 'feed-step',
                    title: '<div class="p p--xs tutorial__num">TIP 1 of 6</div><h4>Customized Card Deck</h4>',
                    text: '<p class="mt--050 step-description">' + 'Select \'My Deck\' to view your personal deck of Care to Talk cards. Select \'Customize\' to browse all Care to Talk cards, and to add or remove cards to your deck.' + '</p>',
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Next',
                            action() {
                                document.getElementById('my-deck-button').click();
                                document.getElementById('ctt-mobile-nav').classList.toggle('active');
                                setTimeout(() => {
                                    tour.next();
                                }, 250)

                            },
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.ctt-tabs',
                                },
                            },
                        ]
                    }
                },
                {
                    attachTo: { element: "#deck-switcher", on: 'bottom' },
                    title: '<div class="p p--xs tutorial__num">TIP 2 of 6</div><h4>View switcher</h4>',
                    text: '<p class="mt--050 step-description">Select how you view Care to Talk cards here. Grid view displays cards in a grid, and is a great way to organize and review cards. Stack view shows cards one at a time, and is great for focusing on one card and facilitating conversations.</p>',
                    scrollTo: false,
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action() {
                                document.getElementById('ctt-mobile-nav').classList.remove('active');
                                document.getElementById('ctt-filter').classList.remove('active');

                                tour.complete();
                            },
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Previous',
                            action() {
                                document.getElementById('ctt-mobile-nav').classList.toggle('active');
                                setTimeout(() => {
                                    tour.back();
                                }, 250)

                            },
                            classes: 'button button--sm button--tertiary next-button'
                        },
                        {
                            text: 'Next',
                            action: tour.next,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '#deck-switcher',
                                },
                            },
                        ]
                    }
                },
                {
                    attachTo: { element: "#deck-share", on: 'top' },
                    title: '<div class="p p--xs tutorial__num">TIP 3 of 6</div><h4>Sharing</h4>',
                    text: '<p class="mt--050 step-description">Print all cards in your deck for later use or share a link to your deck with friends or family.</p>',
                    scrollTo: false,
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action() {
                                document.getElementById('ctt-mobile-nav').classList.remove('active');
                                document.getElementById('ctt-filter').classList.remove('active');

                                tour.complete();
                            },
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Previous',
                            action: tour.back,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                        {
                            text: 'Next',
                            action() {
                                document.getElementById('ctt-mobile-nav').classList.toggle('active');
                                document.getElementById('ctt-filter').classList.toggle('active');
                                setTimeout(() => {
                                    tour.next();
                                }, 250)
                            },
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '#deck-share',
                                },
                            },
                        ]
                    }
                },
                {
                    attachTo: { element: ".ctt-tour-categories", on: 'top' },
                    title: '<div class="p p--xs tutorial__num">TIP 4 of 6</div><h4>Categories</h4>',
                    text: '<p class="mt--050 step-description">Select a Category to browse by topic. Any card you like will be added to the My Likes category, at the far left.</p>',
                    scrollTo: false,
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action() {
                                document.getElementById('ctt-mobile-nav').classList.remove('active');
                                document.getElementById('ctt-filter').classList.remove('active');

                                tour.complete();
                            },
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Previous',
                            action() {
                                document.getElementById('ctt-mobile-nav').classList.toggle('active');
                                document.getElementById('ctt-filter').classList.toggle('active');

                                setTimeout(() => {
                                    tour.back();
                                }, 250)

                            },
                            classes: 'button button--sm button--tertiary next-button'
                        },
                        {
                            text: 'Next',
                            action: tour.next,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['top'],
                                    rootBoundary: '.ctt-tour-categories',
                                },
                            },
                        ]
                    }
                },
                {
                    attachTo: { element: ".ctt-tour-sorting", on: 'top' },
                    title: '<div class="p p--xs tutorial__num">TIP 5 of 6</div><h4>Sorting</h4>',
                    text: '<p class="mt--050 step-description">Select from the list here to change how the cards are sorted.</p>',
                    scrollTo: false,
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action() {
                                document.getElementById('ctt-mobile-nav').classList.remove('active');
                                document.getElementById('ctt-filter').classList.remove('active');
                                tour.complete();
                            },
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Previous',
                            action: tour.back,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                        {
                            text: 'Next',
                            action() {
                                document.getElementById('resources-mobile-btn').click();
                                document.getElementById('ctt-mobile-nav').classList.remove('active');
                                document.getElementById('ctt-filter').classList.remove('active');

                                setTimeout(() => {
                                    tour.next();
                                }, 300);
                            },
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['top'],
                                    rootBoundary: '.ctt-tour-sorting',
                                },
                            },
                        ]
                    }
                },
                {
                    attachTo: { element: ".cards-image-header-container", on: 'bottom' },
                    title: '<div class="p p--xs tutorial__num">TIP 6 of 6</div><h4>Your deck</h4>',
                    text: '<p class="mt--050 step-description">When you\'re ready to get started with your deck, click here. Let the conversations begin!</p>',
                    scrollTo: false,
                    buttons: [
                        {
                            text: 'End Tutorial',
                            action: tour.complete,
                            classes: 'p tutorial__end end-tour-button',
                        },
                        {
                            text: 'Previous',
                            action() {
                                document.getElementById('my-deck-button').click();
                                document.getElementById('ctt-filter').classList.toggle('active');

                                setTimeout(() => {
                                    tour.back();
                                }, 250)

                            },
                            classes: 'button button--sm button--tertiary next-button'
                        },
                        {
                            text: 'Next',
                            action: tour.next,
                            classes: 'button button--sm button--tertiary next-button'
                        },
                    ],
                    popperOptions: {
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom'],
                                    rootBoundary: '.cards-image-header-container',
                                },
                            },
                        ]
                    }
                },
            ];
        },
        tourIsCompleteFor(key, guestId) {
            let storageKey = this.localStoragePath(key, guestId);

            return !!localStorage.getItem(storageKey);
        },
        completeTourForGuest(key, guestId) {
            let storageKey = this.localStoragePath(key, guestId);

            localStorage.setItem(storageKey, Date.now());
        },
        localStoragePath(key, guestId) {
            return 'tour#' + guestId + '#' + key;
        },
        restartTourForGuest(key, guestId) {
            let storageKey = this.localStoragePath(key, guestId);

            localStorage.removeItem(storageKey);
            window.location.reload();
        }
    }
};
