<template>
    <div>
        <div class='ctt-card-wrap ctt-card-wrap--stack' @touchstart="touchStart">
            <transition :name="slideName" tag="div" mode="out-in">
                <deck-card
                    v-for='(card, index) in cards'
                    :key='card.id'
                    v-if='index === currentIndex'
                    :card='card'
                ></deck-card>
            </transition>
        </div>

        <div class="ctt-deck-nav flexed--sm mt--300">
            <button class="ctt-deck-nav__button" @click='currentIndex = 0'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
                    <path d="M7.5,14C4.5,14,2,11.5,2,8.5S4.5,3,7.5,3c1.2,0,2.2,0.4,3.2,1H8v2h6V0h-2v2.5C10.7,1.5,9.2,1,7.5,1 C3.4,1,0,4.4,0,8.5S3.4,16,7.5,16S15,12.6,15,8.5h-2C13,11.5,10.5,14,7.5,14z"></path>
                </svg>
            </button>

            <div class="ctt-deck-nav__progress">
                <div class="ctt-deck-nav__progress-handle" :style="`left: ${progressPercentage}%`"></div>
            </div>

            <button class="ctt-deck-nav__button" @click='randomCard'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
                    <path d="M12,11c-1.493,0-2.012-0.533-2.842-1.481l-1.329,1.519C8.912,12.275,10.075,13,12,13v2l4-3 l-4-3V11z"></path>
                    <path d="M3.842,6.481l1.329-1.519C4.121,3.762,2.899,3,1.185,3H0v2h1.185 C2.466,5,3.087,5.619,3.842,6.481z"></path>
                    <path d="M12,3c-1.717,0-3.018,0.645-3.948,1.708L3.442,9.975C2.873,10.626,2.05,11,1.185,11H0v2h1.185 c1.441,0,2.813-0.623,3.764-1.708l4.609-5.268C10.219,5.268,11.037,5,12,5v2l4-3l-4-3V3z"></path>
                </svg>
            </button>
        </div>

        <div class='ctt-card-wrap stack--buttons grid-row grid-row--aic removed--sm'>

            <div class='deck-btn--cont'>
                <button
                    type='button'
                    class='button button--xs'
                    @click='previousCard'
                    :disabled='!allowPrevious'
                >
                    <svg
                        width='20'
                        height='20'
                        viewBox='0 0 24 24'
                        stroke='#62727B'
                        stroke-width='2.4'
                        fill='none'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    >
                        <line x1='19' y1='12' x2='5' y2='12'></line>
                        <polyline points='11 18 5 12 11 6'></polyline>
                    </svg>
                    <span>Prev</span>
                </button>
            </div>
            <div class='deck-btn--cont'>
                <button
                    type='button'
                    class='button button--xs'
                    @click='randomCard'
                >
                    <span>Random</span>
                    <svg class="icon icon-shuffle"><use xlink:href="#icon-shuffle"></use></svg>
                    <symbol id="icon-shuffle" viewBox="0 0 32 32">
                        <path d="M24 22h-3.172l-5-5 5-5h3.172v5l7-7-7-7v5h-4c-0.53 0-1.039 0.211-1.414 0.586l-5.586 5.586-5.586-5.586c-0.375-0.375-0.884-0.586-1.414-0.586h-6v4h5.172l5 5-5 5h-5.172v4h6c0.53 0 1.039-0.211 1.414-0.586l5.586-5.586 5.586 5.586c0.375 0.375 0.884 0.586 1.414 0.586h4v5l7-7-7-7v5z"></path>
                    </symbol>
                </button>
            </div>
            <div class='deck-btn--cont'>
                <button
                    type='button'
                    class='button button--xs'
                    @click='nextCard'
                    :disabled='!allowNext'
                >
                    <span>Next</span>
                    <svg
                        width='20'
                        height='20'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        stroke-width='2.4'
                        fill='none'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    >
                        <line x1='19' y1='12' x2='5' y2='12'></line>
                        <polyline
                            points='11 18 5 12 11 6'
                            style='transform: rotate(180deg); transform-origin: 50% 50%;'
                        ></polyline>
                    </svg>
                </button>
            </div>
        </div>

    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import collectorActions from '../../../mixins/collectorActions';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'deck-stack',
    props: ['cards'],
    mixins: [collectorActions],
    mounted() {

    },
    data() {
        return {
            overlay: false,
            currentIndex: 0,
            slideName: 'topic-left',
        };
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
        }),
        allowNext() {
            return this.currentIndex < this.cards.length - 1;
        },
        allowPrevious() {
            return this.currentIndex > 0;
        },
        progressPercentage() {
            if (this.currentIndex === 0) return 0;
            // we must use 90% this so we don't go out of width bounds
            if (this.currentIndex === this.cards.length - 1) return 90;
            let total = this.cards.length - 1;
            return ((this.currentIndex) / total) * 100;
        }
    },
    methods: {
        nextCard() {
            this.slideName = 'topic-left';
            this.currentIndex++;
        },
        previousCard() {
            this.slideName = 'topic-right';
            this.currentIndex--;
        },
        randomCard() {
            let newIndex = Math.floor(Math.random() * this.cards.length);

            if (newIndex === this.currentIndex && this.cards.length > 1)
                return this.randomCard();
            else if (newIndex > this.currentIndex)
                this.slideName = 'topic-left';
            else
                this.slideName = 'topic-right';

            this.currentIndex = newIndex;
        },
        touchStart (touchEvent) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXStart = touchEvent.changedTouches[0].clientX;
            addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), {once: true});
        },
        touchEnd (touchEvent, posXStart) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXEnd = touchEvent.changedTouches[0].clientX;
            if (posXStart < posXEnd && this.allowPrevious) {
                this.previousCard(); // swipe right
            } else if (posXStart > posXEnd && this.allowNext) {
                this.nextCard(); // swipe left
            }
        }
    },
    watch: {}
};
</script>

<style lang='scss'>
.stack--buttons {
    margin-top: 48px;
}

.deck-btn--cont {
    min-height: 40px;
    margin-top: -20px;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: auto;
}

.icon {
    margin-top: 3px;
    margin-left: 5px;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.ctt-card {
    //-webkit-perspective: 1000;
    //-webkit-backface-visibility: hidden;
    //transform: translate3d(0px,0px,0px);
    //animation-fill-mode: forwards;
}


.topic-left-enter {
    opacity: 0;
    visibility: hidden;
    transform: translateX(100%);
}

.topic-right-enter {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%);
}
.topic-right-enter-active,
.topic-right-leave-active,
.topic-left-enter-active,
.topic-left-leave-active {
    opacity: 1;
    visibility: visible;
}
.topic-right-enter,
.topic-right-leave-to,
.topic-left-enter,
.topic-left-leave-to{
    opacity: 0;
    visibility: hidden;
}

.topic-left-enter-active,
.topic-right-enter-active {
    transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
    overflow: hidden;
}
</style>
