<template>
    <div class="onboard__slide" :class="{ current: isCurrent }">
        <div class="onboard-quiz">
            <div class="onboard-quiz__slide" :class="{ active: !lastNodeInPathway }">
                <component
                    :is="component"
                    :revert-choice="revertChoice"
                    @loadNext="loadNextNode"
                    @updateRevertChoice="revertChoice = false"
                    @backStep="goStepBack"
                    :key="content.id"
                ></component>
            </div>

<!--            <div class="onboard-quiz__slide" :class="{ active: lastNodeInPathway && !guest.email }">-->
<!--                <email-form-content></email-form-content>-->
<!--            </div>-->

            <pathway-nodes-header
                :content-type="node.content ? node.content.content_type.name : ''"
                @goBack="goBack"
                @loadNext="loadNextNode"
                :key="content.id"
            ></pathway-nodes-header>

            <pathway-nodes-footer></pathway-nodes-footer>
        </div>
    </div>
</template>
<script>
import collectorActions from '../../../mixins/collectorActions';
import { mapGetters } from 'vuex';

export default {
    name: 'pathway-intro-nodes',
    props: ['originalNode', 'isCurrent'],
    mixins: [collectorActions],
    data() {
        return {
            component: '',
            revertChoice: false,
            typeMap: {
                Iframe: 'IntroIframeContent',
                Video: 'IntroVideoContent',
                QuestionSet: 'QuestionSetContent',
                Text: 'TextContent',
                Card: 'CardContent',
                Link: 'LinkContent'
            }
        };
    },
    mounted() {
        this.$store.dispatch('storeAndSetNode', this.originalNode);
        this.loadContent(this.originalNode.content);
        this.loadNodeAction(this.originalNode);
    },
    computed: {
        ...mapGetters({
            guest: 'guest',
            node: 'node',
            content: 'content',
            lastNodeInPathway: 'lastNodeInPathway',
            latestAnswer: 'latestAnswer'
        })
    },
    methods: {
        loadContent(content) {
            content = content || this.originalNode.content;

            if (this.node.multi_content_cards) {
                this.component = 'MultiContentCards';
            } else {
                this.component = this.node.multi_content
                    ? 'MultiContent'
                    : this.typeMap[content.content_type.name];
            }

            this.$store.commit('SET_CONTENT', content);
        },
        loadNextNode() {
            this.$store.dispatch('loadNextNode').then(() => {
                if (!this.lastNodeInPathway) {
                    this.loadContent(this.node.content);
                    this.loadNodeAction(this.node);
                }
            });
        },
        goBack() {
            this.revertChoice = true;

            if (this.node.origin) {
                if (
                    this.node.content.content_type.name !== 'QuestionSet' ||
                    this.latestAnswer === null
                ) {
                    this.$emit('loadIntro');
                }
            }
        },
        goStepBack() {
            if (this.node.origin) {
                this.$emit('loadIntro');
            } else {
                this.$store.dispatch('loadPreviousNode').then(() => {
                    this.loadContent(this.node.content);
                    this.goBackAction(this.node);
                });
            }
        }
    },
    watch: {
        lastNodeInPathway() {
            if (this.lastNodeInPathway === true) {
                axios.post('/guest/api/force_login')
                    .then(res => {
                        window.location = '/';
                    });
            }
        }
    }
};
</script>

<style lang="scss"></style>
