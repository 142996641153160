import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        pathway: {},
        content: {},
        guest: {},
        node: {},
        lastNodeInPathway: false,
        latestAnswer: false,
        feedContent: [],
        backAllowed: false,
        backToFeed: false,
        deckView: 'grid',
        deckTab: 'my_deck',
        showSuggestionModal: false,
        deckCards: [],
        guestCards: [],
        deckLoading: false,
        deckCategories: [],
        snackbarMessage: '',
        sharedDeckFrom: false, // // false or guest that's being shared
        forceTab: null,
        addedCardsTotal: 0, // the cards added to deck in this browser session
        showLoginModal: false,
        showRewardsModal: false,
        deckResources: [],
        dismissedRewardsModal: false,
        dismissedLoginRegisterModal: false,


    },
    mutations: {
        SET_PATHWAY(state, pathway) {
            state.pathway = pathway;
        },
        SET_CONTENT(state, content) {
            state.content = content;
        },
        SET_GUEST(state, guest) {
            state.guest = guest;
            console.log('STATE GUEST', state.guest);
        },
        SET_NODE(state, node) {
            state.node = node;
        },
        SET_LATEST_ANSWER(state, latestAnswer) {
            state.latestAnswer = latestAnswer;
        },
        SET_FEED_CONTENT(state, content) {
            state.feedContent = content;
        },
        SET_BACK_ALLOWED(state, value) {
            state.backAllowed = value;
        },
        SET_BACK_TO_FEED(state, value) {
            state.backToFeed = value;
        },
        SET_DECK_VIEW(state, value) {
            state.deckView = value;
        },
        SET_DECK_TAB(state, value) {
            state.deckTab = value;
        },
        SET_FORCE_TAB(state, value) {
            state.forceTab = value;
        },
        SET_SHOW_SUGGESTION_MODAL(state, value) {
            state.showSuggestionModal = value;
        },
        SET_DECK_CARDS(state, value) {
            state.deckCards = value;
        },
        SET_GUEST_CARDS(state, value) {
            state.guestCards = value;
        },
        SET_DECK_LOADING(state, value) {
            state.deckLoading = value;
        },
        SET_DECK_CATEGORIES(state, value) {
            state.deckCategories = value;
        },
        SET_DECK_VIDEOS(state, value) {
            state.deckVideos = value;
        },
        SET_DECK_LINKS(state, value) {
            state.deckLinks = value;
        },
        SET_DECK_RESOURCES(state, value) {
            state.deckResources = value;
        },
        SET_SNACKBAR_MESSAGE(state, value) {
            state.snackbarMessage = value;
            setTimeout(() => state.snackbarMessage = '', 5000);
        },
        SET_SHARED_DECK_FROM(state, value) {
            state.sharedDeckFrom = value;
        },
        SET_SHOW_LOGIN_MODAL(state, value) {
            if (value === false) {
                state.dismissedLoginRegisterModal = true;
            }

            state.showLoginModal = value;
        },
        SET_SHOW_REWARDS_MODAL(state, value) {
            if (value === false) {
                state.dismissedRewardsModal = true;
            }

            state.showRewardsModal = value;
        },
    },
    getters: {
        pathway: state => state.pathway,
        content: state => state.content,
        guest: state => state.guest,
        node: state => state.node,
        lastNodeInPathway: state => state.lastNodeInPathway,
        latestAnswer: state => state.latestAnswer,
        feedContent: state => state.feedContent,
        backAllowed: state => state.backAllowed,
        backToFeed: state => state.backToFeed,
        deckView: state => state.deckView,
        deckTab: state => state.deckTab,
        forceTab: state => state.forceTab,
        showSuggestionModal: state => state.showSuggestionModal,
        deckCards: state => state.deckCards,
        guestCards: state => state.guestCards,
        deckLoading: state => state.deckLoading,
        deckCategories: state => state.deckCategories,
        deckLinks: state => state.deckLinks,
        deckVideos: state => state.deckVideos,
        deckResources: state => state.deckResources,
        snackbarMessage: state => state.snackbarMessage,
        sharedDeckFrom: state => state.sharedDeckFrom,
        addedCardsTotal: state => state.addedCardsTotal,
        showLoginModal: state => state.showLoginModal,
        showRewardsModal: state => state.showRewardsModal,
        dismissedRewardsModal: state => state.dismissedRewardsModal,
        dismissedLoginRegisterModal: state => state.dismissedLoginRegisterModal,
    },
    actions: {
        submitEmail({ state, commit }, email) {
            return axios.post('/guest/api/email', { email: email }).then(res => {
                commit('SET_GUEST', res.data.guest);

                return res;
            });
        },
        storeAndSetNode({ state, commit }, node) {
            commit('SET_NODE', node);

            if (node.content) {
                axios.post('/guest/api/nodes/guest_content', {
                    guest_id: state.guest.id,
                    content_id: node.content.id,
                    pathway_id: node.pathway_id,
                    node_id: node.id
                });
            }
        },
        loadNextNode({ state, dispatch }) {
            let nodeId = state.node.id || null;
            return axios.post('/guest/api/nodes/load_next/' + nodeId).then(res => {
                if (res.data.node !== false) {
                    dispatch('storeAndSetNode', res.data.node);
                } else {
                    state.lastNodeInPathway = true;
                }
            });
        },
        loadPreviousNode({ state, dispatch, commit }) {
            return axios.delete('/guest/api/nodes/load_previous/' + state.node.id).then(res => {
                dispatch('storeAndSetNode', res.data.node);
                commit('SET_LATEST_ANSWER', res.data.latestAnswer);
            });
        },
        bookmarkContent({ state, commit }, content) {
            axios
                .post('/guest/api/nodes/bookmark_content', {
                    guest_id: state.guest.id,
                    content_id: content.id
                })
                .then(res => {
                    commit('SET_GUEST', res.data.guest);
                });
        },
        discardContent({ state, commit }, content) {
            axios
                .post('/guest/api/content/discard', {
                    guest_id: state.guest.id,
                    content_id: content.id
                })
                .then(res => {
                    commit('SET_GUEST', res.data.guest);
                    let index = state.feedContent.findIndex(x => x.id === content.id);

                    if (index > -1) {
                        state.feedContent.splice(index, 1);
                    }
                });
        },
        closeFeedWelcome({ commit, state }) {
            axios.put('/guest/api/update', { welcome_message_viewed: true }).then(res => {
                commit('SET_GUEST', res.data.guest);
            });
        },
        removeContentBookmark({ state, commit }, content) {
            axios.delete('/guest/api/nodes/remove_bookmark/' + content.id).then(res => {
                commit('SET_GUEST', res.data.guest);
            });
        },
        addCardToDeck({state, commit}, card) {
            state.deckLoading = true;
            return axios
                .post('/guest/api/deck/card', {
                    guest_id: state.guest.id,
                    content_id: card.id
                })
                .then(res => {
                    state.guestCards.push(card);
                    commit('SET_GUEST', res.data.guest);
                    state.deckLoading = false;
                    state.addedCardsTotal++;
                });
        },
        removeCardFromDeck({state, commit}, card) {
            state.deckLoading = true;
            axios
                .delete('/guest/api/deck/card/' + card.id)
                .then(res => {
                    let index = _.findIndex(state.guestCards, { id: card.id});

                    if (index > -1) {
                        state.guestCards.splice(index, 1);
                    }

                    commit('SET_GUEST', res.data.guest);
                    state.deckLoading = false;
                });
        },
        toggleFavoriteCard({state, commit}, card) {
            state.deckLoading = true;
            if (state.guest.favorites.includes(card.id)) {
                card.favorites_count--;
            } else {
                card.favorites_count++;
            }
            axios
                .put('/guest/api/deck/card/toggle_favorite', {
                    guest_id: state.guest.id,
                    content_id: card.id
                })
                .then(res => {
                    commit('SET_GUEST', res.data.guest);
                    let contentIndex = state.guestCards.findIndex(card => card.id === res.data.content.id);

                    if (contentIndex > -1) {
                        state.guestCards.splice(contentIndex, 1, res.data.content);
                    }

                    state.deckLoading = false;
                });
        },
        setDeckCategory({state, commit}, {category, favorites, order}) {
            state.deckLoading = true;

            const params = new URLSearchParams({
                guest_id: state.sharedDeckFrom ? state.sharedDeckFrom.id : state.guest.id,
                category: category,
                favorites: favorites ?? false,
                order: order
            }).toString();

            axios
                .get('/guest/api/deck/category?' + params)
                .then(res => {
                    commit('SET_DECK_CARDS', res.data.deck_cards);
                    commit('SET_GUEST_CARDS', res.data.guest_cards);
                    state.deckLoading = false;
                });
        },
        submitSuggestion({state, commit}, {type, content}) {
            state.deckLoading = true;

            return axios
                .post('/guest/api/suggestion', {
                    guest_id: state.guest.id,
                    type: type,
                    content: content
                });
        },
    }
});
