<template>
    <div class="resource">
        <content-header></content-header>

        <div class="mt--200 resource__video">
            <transition name="fade" mode="out-in">
                <div v-if="!videoLoaded">
                    <img
                        :src="content.thumbnail_path"
                        alt="image"
                        class="resource__cover"
                        v-if="!videoLoaded"
                    />
                    <button type="button" class="resource__play" @click="loadVideo"></button>
                </div>
                <youtube
                    class="resource__cover"
                    ref="youtube"
                    :video-id="videoId"
                    v-else
                    :player-width="content.settings.width"
                    :player-height="content.settings.height"
                    @ready="videoReady"
                    @ended="videoEnded"
                ></youtube>
            </transition>
        </div>
    </div>
</template>
<script>
import Time from '../../../mixins/Time';
import collectorActions from '../../../mixins/collectorActions';
import VideoMixin from '../../../mixins/VideoMixin';

export default {
    name: 'video-content',
    mixins: [Time, collectorActions, VideoMixin],
    mounted() {
        this.$store.commit("SET_BACK_TO_FEED", true);
    }
};
</script>

<style lang="scss">
.video-holder {
    display: flex;
    justify-content: center;
}
</style>
